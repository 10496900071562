import "../pages/btnpag.css";

const sliderStyles = {
  height: "100%",
  position: "relative",
};

const slideStyles = {
  width: "500px",
  height: "100%",
  borderRadius: "10px",
  backgroundPosition: "center",
  backgroundSize: "cover",
};


function Services() {
  return(
    <>
    <div className='hhsfl'>
      <div style={sliderStyles} >
      <video style={slideStyles} autoplay="" loop="loop" playsinline="" muted="" id="myVideo" src="https://res.cloudinary.com/dg5umeyhj/video/upload/v1678987972/services-1_wamkjp.mp4">
            </video>
      </div>
      <div className='ptxh'>
      <br/>
        <h5>Services of Genusia Life Sciences...!</h5>
        <br/>
        <p>Welcome to Genusia Life Sciences' information services page. We are a leading life sciences company dedicated to improving the health and wellbeing of people worldwide. Our mission is to create innovative solutions to meet the needs of patients and healthcare providers, and to support the discovery and development of new therapies.
          <br/>
          <br/>
          At Genusia Life Sciences, we offer a range of information services designed to provide valuable insights and knowledge to our customers. Our services include:<br/>
        <br/>
        Scientific Publications: We publish a range of scientific papers, articles, and research studies that cover various aspects of life sciences. Our publications are peer-reviewed, and we ensure that they meet the highest standards of scientific rigor and accuracy.
</p>
        <br/>
        <br/>
      </div>
    </div>
    <div className='hhsfl'>
    <div className='ptxh'>
    <br/>
      <p>Educational Resources: We offer a range of educational resources, including webinars, podcasts, and online courses, that cover various topics related to life sciences. Our resources are designed to be accessible to a wide range of audiences, from healthcare professionals to patients and their families.
        <br/>
        <br/>
        Clinical Trials: We conduct clinical trials to test the safety and efficacy of new therapies. Our trials follow strict ethical and regulatory standards and are designed to generate reliable data that can inform clinical practice.
<br/>
      <br/>
      Consultancy Services: We offer consultancy services to healthcare providers, pharmaceutical companies, and other organizations in the life sciences sector. Our team of experts can provide valuable insights and guidance on various aspects of drug development, clinical research, and regulatory affairs.
</p>
      <br/>
      <br/>
    </div>
    <div style={sliderStyles} >
    <video style={slideStyles} autoplay="" loop="loop" playsinline="" muted="" id="myVideo" src="https://res.cloudinary.com/dg5umeyhj/video/upload/v1678987967/services-2_abxz9g.mp4">
            </video>
    </div>
  </div>
  </>
  )
}

export default Services;