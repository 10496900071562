import "../pages/btnpag.css";

const sliderStyles = {
  height: "100%",
  position: "relative",
};

const slideStyles = {
  width: "500px",
  height: "100%",
  borderRadius: "10px",
  backgroundPosition: "center",
  backgroundSize: "cover",
};


function Home() {
  return(
    <>
    <div className='hhsfl'>
      <div style={sliderStyles} >
      <video style={slideStyles} autoplay="" loop="loop" playsinline="" muted="" id="myVideo" src="https://res.cloudinary.com/dg5umeyhj/video/upload/v1678987970/home-1_cxroki.mp4">
            </video>
      </div>
      <div className='ptxh'>
      <br/>
        <h5>Welcome to Genusia Life Sciences...!</h5>
        <br/>
        <p>A leading biotechnology company focused on developing innovative treatments for unmet medical needs. Our mission is to improve the health and well-being of patients by developing breakthrough therapies that address some of the world's most challenging diseases.
          <br/>
          <br/>
          At Genusia Life Sciences, we are committed to advancing the frontiers of medicine through cutting-edge research and development. Our team of talented scientists and researchers is dedicated to discovering and developing new therapies that have the potential to make a real difference in patients' lives.<br/>
        <br/>
        We are focused on developing therapies for a variety of diseases, including cancer, autoimmune disorders, and infectious diseases. Our research and development programs are based on the latest scientific insights and use state-of-the-art technologies to identify and validate novel drug targets.

</p>
        <br/>
        <br/>
      </div>
    </div>
    <div className='hhsfl'>
    <div className='ptxh'>
    <br/>
      <p>In addition to our core research and development programs, we also have a strong focus on clinical development and commercialization. We work closely with regulatory agencies and healthcare providers to ensure that our therapies are safe, effective, and accessible to patients who need them.
        <br/>
        <br/>
        At Genusia Life Sciences, we believe that collaboration is essential to success in the biotech industry. We partner with academic institutions, biotech companies, and pharmaceutical companies to leverage our expertise and resources and accelerate the development of new therapies.
<br/>
      <br/>
      We are proud of our track record of success in the biotech industry and remain committed to bringing innovative therapies to patients in need. We invite you to learn more about our company and our work and to join us in our mission to improve the lives of patients around the world
</p>
      <br/>
      <br/>
    </div>
    <div style={sliderStyles} >
    <video style={slideStyles} autoplay="" loop="loop" playsinline="" muted="" id="myVideo" src="https://res.cloudinary.com/dg5umeyhj/video/upload/v1678987960/home-2_wy2hdy.mp4">
            </video>
    </div>
  </div>
  </>
  )
}

export default Home;