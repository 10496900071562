import "../pages/btnpag.css";
import {FaMapMarkerAlt, FaPhone, FaWhatsapp} from "react-icons/fa";
import {BsCalendarWeek,BsFillCalendarWeekFill} from "react-icons/bs";
import {SiSocialblade} from "react-icons/si"
import {AiOutlineMail, AiFillInstagram, AiFillTwitterCircle, AiFillLinkedin} from "react-icons/ai";

const sliderStyles = {
  height: "100%",
  position: "relative",
};

const slideStyles = {
  width: "500px",
  height: "100%",
  borderRadius: "10px",
  backgroundPosition: "center",
  backgroundSize: "cover",
};

const praStyles ={
  padding: '0px 0px 0px 59px',
}

const pralStyles ={
  padding: '0px 0px 0px 50px',

}


function whats() {
  window.open("https://wa.me/919989944990/?text=Welcome to Acharyaa Medical Placements");
}

function insta() {
  window.open("https://www.instagram.com/");
}

function twit(){
  window.open("https://twitter.com/");
}

function linked(){
  window.open("https://www.linkedin.com/");
}

function Contactus() {
  return(
    <>
    <br/>
    <div className='hhsfl'>
    <div className='ptxh'>
      <br/>
      <h5>Welcome to the Contact Us page for Genusia Life Sciences</h5>
    <br/>
      <p>We appreciate your interest in our company and would love to hear from you. Please find below the ways to get in touch with us:
        <br/>
        <br/>
        Contact Form: You can reach out to us by filling out the Contact Us form on our website. We will get back to you within 24 hours of receiving your message.
<br/>
      <br/>
</p>
      <br/>
      <br/>
    </div>
    <div style={sliderStyles} >
    <video style={slideStyles} autoplay="" loop="loop" playsinline="" muted="" id="myVideo" src="https://res.cloudinary.com/dg5umeyhj/video/upload/v1678987962/contact-us-2_kt192m.mp4">
            </video>
    </div>
  </div>

    <div className='hhsfl'>
      <div style={sliderStyles} >
      <video style={slideStyles} autoplay="" loop="loop" playsinline="" muted="" id="myVideo" src="https://res.cloudinary.com/dg5umeyhj/video/upload/v1679038269/GENUSIA%20LIFE%20SCIENCES/contact-us-4_wv4pzg.mp4">
      </video>
      <br/>
      <br/>
      </div>
      <div className='ptxh'>
        <br/>
      <h3>General Information</h3>
        <br/>
        <a className="lap" ><FaPhone/></a>  If you prefer to speak with someone over the phone,<br/>
        <p style={praStyles}> please call us at +91-9989944990</p>
        <p style={praStyles}> Our customer service team is available<br/>
        </p>
        <p style={praStyles}>Monday to Friday from 9:00 AM to 5:00 PM EST.</p>
        <br/>
        <a className="lae" ><AiOutlineMail/></a> You can also send us an email at
          <p style={praStyles}> info@genusialifesciences.com<br/> with your questions, comments, or concerns. We will do our best to respond within one business day.
          </p>
        <br/>
        <a className="las" ><SiSocialblade/></a> You can also connect with us on our social media channels
        <p style={praStyles}> Twitter, LinkedIn, and Instagram. We are always happy to engage with our followers and answer any questions you may have.</p>
        <p>.</p>
        <a className="laic" onClick={whats}><FaWhatsapp/></a>
        <a className="laic" onClick={insta}><AiFillInstagram/></a>
        <a className="laic" onClick={twit}><AiFillTwitterCircle/></a>
        <a className="laic" onClick={linked}><AiFillLinkedin/></a>
      <br/>
      </div>
    </div>
    <br/>
    <div className='hhsfl'>
    <div className='ptxh'>
    <br/>
    <div style={pralStyles}>
      <a className="laa" ><FaMapMarkerAlt/>
        </a> If you would like to Visit us <br/><p style={pralStyles}>please use the following address:<br/>
        <b>Genusia Life Sciences</b><br/> PlotNo 103,104, Orbit plaza, New prabhadevu marg,
        Near Bank Bus Stop, mombai, 400025.</p>
        <br/>
        <h3 style={pralStyles}>Working Hours</h3>
        <a className="laics"><BsCalendarWeek/></a> Monday-Friday : 8:00 AM - 8:00 PM<br/>
        <a className="laics"><BsFillCalendarWeekFill/></a> Saturday : 10:00 AM - 6:00 PM<br/>
        <br/>
        <p> At Genusia Life Sciences, we value your feedback and appreciate your interest in our company. We are committed to providing exceptional customer service and will do our best to address any questions or concerns you may have. Thank you for visiting our website, and we look forward to hearing from you soon. </p>
      </div>
      <br/>
      <br/>
    </div>
    <div style={sliderStyles} >
    <video style={slideStyles} autoplay="" loop="loop" playsinline="" muted="" id="myVideo" src="https://res.cloudinary.com/dg5umeyhj/video/upload/v1679036951/contact-us-3_bedlta.mp4">
      </video>
    </div>
  </div>
    </>
  )
}

export default Contactus;