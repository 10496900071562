import "../pages/btnpag.css";

const sliderStyles = {
  height: "100%",
  position: "relative",
};

const slideStyles = {
  width: "500px",
  height: "100%",
  borderRadius: "10px",
  backgroundPosition: "center",
  backgroundSize: "cover",
};


function Aboutus() {
  return(
    <>
    <div className='hhsfl'>
      <div style={sliderStyles} >
      <video style={slideStyles} autoplay="" loop="loop" playsinline="" muted="" id="myVideo" src="https://res.cloudinary.com/dg5umeyhj/video/upload/v1678987964/about-1_z9qmtm.mp4">
            </video>
      </div>
      <div className='ptxh'>
      <br/>
        <h5>About Genusia Life Sciences...!</h5>
        <br/>
        <p>We are a research-driven pharmaceutical company focused on improving the lives of people worldwide by developing innovative and high-quality medicines.
          <br/>
          <br/>
        Our mission is to discover, develop, and deliver innovative pharmaceutical products that address unmet medical needs 
        and improve the health of people worldwide. We are committed to excellence in science, 
        patient-centeredness, and social responsibility.<br/>
        <br/>
        Our team of dedicated professionals includes experts in medicine, science, regulatory affairs, 
        manufacturing, marketing, and finance. We work collaboratively to ensure that our products are safe, 
        effective, and accessible to all who need them.
</p>
        <br/>
        <br/>
      </div>
    </div>
    <div className='hhsfl'>
    <div className='ptxh'>
    <br/>
      <p>At Genusia Life Sciences, we believe that innovation and collaboration are the keys to success. We invest heavily in research and development to discover new treatments and cures for diseases that affect millions of people worldwide. Our focus areas include oncology, immunology, rare diseases, and neuroscience.
        <br/>
        <br/>
        We are also committed to being socially responsible and giving back to the communities we serve. We support charitable organizations and initiatives that improve health and well-being, education, and environmental sustainability.
<br/>
      <br/>
      Thank you for your interest in Genusia Life Sciences. We look forward to continuing our work to improve the lives of people worldwide through innovative and life-changing medicines.
</p>
      <br/>
      <br/>
    </div>
    <div style={sliderStyles} >
    <video style={slideStyles} autoplay="" loop="loop" playsinline="" muted="" id="myVideo" src="https://res.cloudinary.com/dg5umeyhj/video/upload/v1678987971/about-2_ixa6ud.mp4">
            </video>
    </div>
  </div>
  </>
  )
}

export default Aboutus;