import { useRef } from "react";
import {FaBars, FaTimes} from "react-icons/fa";
import "../navbar/navbar.css";
import GenusiaLogo from '../Genusia-logo.png';


function Navbar(){
    
    const logoStyles = {
        padding: "10px",
        height: "80px",
        position: "relative",
    };

    const buttAlin ={
        position: "relative",
        justifyContent: "space-between",
    };
    
    const navRef = useRef();
    const showNavbar =() => {
        navRef.current.classList.toggle("responsive_nav")
    }
    return(
        <header>
            <div>
            <a className="logo" href="/Home"><img style={logoStyles} src={GenusiaLogo} alt="Logo"/></a>
            </div>
            
        
            <div style={buttAlin}>
            <nav ref={navRef}>
                <a href="home">HOME</a>
                <a href="aboutus">ABOUT US</a>
                <a href="services" >SERVICES</a>
                <a href="contactus">CONTACT US</a>
                <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                    <FaTimes />
                </button>
            </nav>
            </div>
            <button onClick={showNavbar} className="nav-btn">
                <FaBars/>
            </button>
        </header>
    );
}



export default Navbar;